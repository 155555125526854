<template>
  <div
    v-loading="syncing"
    :class="{ 'notes-empty': notes.length < 1 }"
    class="notes">
    <div class="notes-header">
      <div class="row">
        <div class="col-md-6">
          <h2>
            Notes
            <template v-if="total">
              ({{ total }})
            </template>
          </h2>
        </div>
        <add-note @add="sendNote" />
      </div>
    </div>
    <ul class="notes-container">
      <note
        v-for="note in notes"
        :key="note.id"
        :title="note.title"
        :desc="note.description"
        :pending="note.pending"
        :success="note.success"
        :task="note.task"
        :footer="getRelativeTimes(note.noteId).displayTime"
        @edit="editNoteById(note.noteId, ...arguments)"
        @resend="resendNote(note)"
        @delete="deleteNote(note)"/>
    </ul>
    <div
      v-if="notes.length < 1">
      <div
        v-loading="loading"
        class="empty-state">
        <div class="icon-container">
          <span class="chart-group">
            <i class="line-chart-icon"/>
            <i class="table-shape-icon"/>
            <i class="circle circle-top"/>
            <i class="pie-shape-icon"/>
            <i class="calendar-shape-icon"/>
            <i class="bar-shape-icon"/>
            <i class="circle circle-bottom"/>
          </span>
          <i class="notification-bell-icon"/>
        </div>
        <h2>No Notes Yet!</h2>
        <p>Click "Add Note" button to create new one.</p>
      </div>
    </div>
    <div
      v-if="remainingPages > 0"
      class="content-padding centered">
      <button
        v-if="!loading"
        class="btn btn-text secondary"
        @click="getNextPage">
        Load {{ Math.min(remainingPages, pageLimit) }} more
      </button>
      <span v-else-if="loading">Loading...</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Note from '~/components/company/notes/Note'
import AddNote from '~/components/company/notes/AddNote'

export default {
  components: {
    Note,
    AddNote
  },
  data() {
    return {
      relativeTimes: [],
      sortableFields: ['Title', 'Date'],
      dialogVisible: false,
      noteDescription: '',
      noteTitle: '',
      loading: false
    }
  },
  computed: {
    ...mapState('company/notes', {
      notes: state => state.notes,
      remainingPages: state => state.remainingPages,
      total: state => state.totalNotes,
      pageLimit: state => state.pageLimit,
      syncing: state => state.syncing
    })
  },
  mounted() {
    this.refreshRelativeTimes()
    this.refresher = setInterval(this.refreshRelativeTimes, 60 * 1000)
  },
  destroyed() {
    clearInterval(this.refresher)
  },
  methods: {
    ...mapActions('company/notes', [
      'fetchNotes',
      'sendNote',
      'getMoreNotes',
      'resendNote',
      'deleteNote',
      'editNote'
    ]),
    editNoteById(noteId, newNote) {
      this.editNote({ ...newNote, noteId })
    },
    getNextPage() {
      this.loading = true
      this.getMoreNotes()
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    refreshRelativeTimes() {
      this.relativeTimes = this.notes.map(val => {
        const timestamp = Number.isInteger(val.updatedAt)
          ? val.updatedAt
          : val.createdAt
        const displayTime =
          this.$date.formatDistance(new Date(timestamp * 1000), new Date()) +
          ' ago'

        return { id: val.noteId, displayTime }
      })
    },
    getRelativeTimes(id) {
      return this.relativeTimes.find(val => val.id === id) || {}
    }
  }
}
</script>
<style src="~/assets/scss/sections/company/notes.scss" lang="scss" />
