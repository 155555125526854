<template>
  <div class="col-md-6">
    <a
      class="btn btn-default btn-text pull-right"
      @click.prevent="isOpen = true">
      Add Note
    </a>
    <el-dialog
      :visible.sync="isOpen"
      title="Add Notes"
      class="modal-for-notes">
      <fieldset class="inner-fieldset">
        <el-popover
          v-model="isTitleFocused"
          placement="left"
          trigger="manual"
          content="What title do you want for this note?"
          popover-class="for-notes light">
          <input
            slot="reference"
            ref="noteTitle"
            v-model="note.title"
            :class="{ error: titleError }"
            class="full-width"
            placeholder="Enter Title"
            @focus="isTitleFocused = true"
            @blur="isTitleFocused = false">
        </el-popover>
      </fieldset>
      <fieldset class="inner-fieldset">
        <el-popover
          v-model="isDescriptionFocused"
          placement="left-start"
          trigger="manual"
          content="What description would you like for this note?"
          popover-class="for-notes light">
          <textarea
            slot="reference"
            v-model="note.description"
            :class="{ error: descriptionError }"
            class="full-width"
            placeholder="Enter Notes"
            @focus="isDescriptionFocused = true"
            @blur="isDescriptionFocused = false"
            @keyup.ctrl.enter="submit"/>
        </el-popover>
      </fieldset>
      <fieldset class="tip-section">
        <i class="bulb-icon"/>
        <p>Notes are only visible to your User Group.</p>
      </fieldset>
      <button
        slot="footer"
        class="btn btn-text"
        @click="submit">
        Save
      </button>
    </el-dialog>
  </div>
</template>

<script>
import NoteErrorNotification from './NoteErrorNotification'

export default {
  data() {
    return {
      note: {
        title: '',
        description: ''
      },
      isOpen: false,
      notificationInstance: null,
      titleError: false,
      descriptionError: false,
      isDescriptionFocused: false,
      isTitleFocused: false,
      isTrue: true
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal === false) {
        clearTimeout(this.noteTitleTimeOutId)
        return this.clear()
      }
      clearTimeout(this.noteTitleTimeOutId)

      this.noteTitleTimeOutId = setTimeout(() => {
        this.$refs.noteTitle.focus()
        this.noteTitleTimeOutId = null
      }, 150)
    }
  },
  methods: {
    submit() {
      const h = this.$createElement
      const errors = []

      this.titleError = false
      this.descriptionError = false

      if (this.notificationInstance) {
        this.notificationInstance.close()
      }

      if (!this.note.title.trim().length) {
        errors.push('Title cannot be blank')
        this.titleError = true
      }

      if (!this.note.description.trim().length) {
        errors.push('Description cannot be blank')
        this.descriptionError = true
      }

      if (errors.length) {
        this.notificationInstance = this.$notify.error({
          message: h(NoteErrorNotification, {
            props: { errors }
          }),
          customClass: 'as-error',
          duration: 3000
        })
        setTimeout(() => {
          this.titleError = false
          this.descriptionError = false
        }, 3000)
        return
      }

      const note = {
        title: this.note.title,
        description: this.note.description
      }

      this.$emit('add', note)
      this.isOpen = false
    },
    clear() {
      this.note.title = ''
      this.note.description = ''
    }
  }
}
</script>

<style>
.v-modal {
  display: none !important;
}

.el-popup-parent--hidden .modal-for-notes {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.el-popup-parent--hidden .site-container {
  z-index: 3;
}
</style>
