<template>
  <div class="alert-notification error">
    <p 
      v-for="error in errors" 
      :key="error" 
      v-html="formatError(error)"/>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatError(error) {
      return error
        .replace('Title', '<span>Title</span>')
        .replace('Description', '<span>Description</span>')
    }
  }
}
</script>
