<template>
  <li v-loading="pending">
    <div 
      v-if="success === false && !pending" 
      class="note-error-message">
      <div class="note-error-content">
        <i class="fa fa-warning" />
        <span>Sorry, this note was not posted</span>
        <strong 
          class="note-error-retry" 
          @click="resend">Try again</strong>
      </div>
    </div>
    <div class="note-author"><span class="circle ">N</span></div>
    <div class="notes-content">
      <div 
        v-if="!editing" 
        class="notes-info">
        <h3>{{ title }}</h3>
        <p>{{ desc }}</p>
        <span class="notes-date">{{ footer }}</span>
      </div>
      <div 
        v-click-outside="cancelEdit" 
        v-else 
        class="notes-edit">
        <fieldset>
          <input 
            ref="newTitle" 
            v-model="newTitle" 
            @keyup.enter="endEdit">
        </fieldset>
        <fieldset>
          <textarea
            ref="newDescription"
            v-model="newDescription"
            @keyup.enter="endEdit"/>
        </fieldset>
        <button 
          class="note-edit-submit btn btn-primary" 
          @click="endEdit">
          Save
        </button>
      </div>
      <div class="note-tool-btns">
        <button 
          class="fa fa-pencil" 
          @click="startEdit"/>
        <button 
          class="fa fa-trash" 
          @click="deleteNote"/>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    footer: {
      type: String,
      default: ''
    },
    pending: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: true
    },
    task: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editing: false,
      newTitle: '',
      newDescription: '',
      doneFocus: false
    }
  },
  updated() {
    if (this.$refs.newDescription && !this.doneFocus) {
      this.$refs.newDescription.focus()
      this.doneFocus = true
    }
  },
  methods: {
    resend() {
      this.$emit('resend')
    },
    deleteNote() {
      this.$emit('delete')
    },
    startEdit() {
      this.newTitle = this.title
      this.newDescription = this.desc
      this.editing = true
    },
    cancelEdit() {
      this.editing = false
      this.doneFocus = false
    },
    endEdit() {
      this.$emit('edit', {
        title: this.newTitle,
        description: this.newDescription
      })
      this.doneFocus = false
      this.editing = false
    }
  }
}
</script>
